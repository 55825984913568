.ms-container {
    width: auto;

	.ms-selectable {
		li {
		  &.ms-elem-selectable, &.ms-elem-selection {
		  	font-size: .75rem;
		  }
		}
	}

	.ms-selection {
		li {
		  &.ms-elem-selection {
		  	font-size: .75rem;
		  }
		}
	}
}

