.header {
	&__content {
		flex-direction: column;
		width: calc(100% - 200px);

		.nav-link {
			i {

				font-size: 1rem;
			}
		}
	}

}