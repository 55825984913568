.daterangepicker {

	.input-mini {
		padding: .25rem .75rem .25rem 1.6875rem;
		font-size: .76563rem;
		line-height: 1.5;
	}

	.daterangepicker_input i {
	  top: 7px;
	}

	.calendar {
		th {
			font-weight: 600;
		}
		td, th {
			min-width: 25px;
			line-height: 23px;
			font-size: .75rem;
		}
	}
	select {
		&.ampmselect, &.hourselect, &.minuteselect, &.secondselect{
			font-size: .75rem;
		}
	}
}
