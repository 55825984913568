// IE10&11 Flexbox fix
@media all and (-ms-high-contrast:none) {
    html {
        display: flex;
        flex-direction: column;
    }
}

// app-dashboard and app-root are Angular2+ selectors. You can add here your own selectors if you need.
.app,
.app-dashboard,
.app-root {
    display: flex;
    flex-direction: column;
}

.app-header {
    flex: 0 0 $navbar-height;
}

.app-footer {
    flex: 0 0 $footer-height;
}

.app-body {
    .main {
        flex: 1;
        min-width: 0;

        .container-fluid {
            margin-top: 0.75rem;
        }
    }

    .sidebar {
        // $sidebar-width is the width of the columns
        flex: 0 0 $sidebar-width; // put the nav on the left
        order: -1;
    }

    .aside-menu {
        // $aside-menu-width is the width of the columns
        flex: 0 0 $aside-menu-width;
    }
}

.app-body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-positive: 1;
    flex-grow: 1;
    overflow-x: hidden;
    height: calc(100vh - 73.5px);
    
    &::-webkit-scrollbar {
        width: 10px;
        margin-right: -10px;
        -webkit-appearance: none;
    } // &::-webkit-scrollbar-button {    }
    &::-webkit-scrollbar-track {
        background-color: lighten($aside-menu-bg, 5%);
        border-right: 1px solid darken($aside-menu-bg, 20%);
        border-left: 1px solid darken($aside-menu-bg, 20%);
    } // &::-webkit-scrollbar-track-piece {    }
    &::-webkit-scrollbar-thumb {
        height: 50px;
        background-color: #d5d5d5;
        background-clip: content-box;
        border-color: transparent;
        border-style: solid;
        border-width: 1px 2px;
    }
}

//
// header
//
.header-fixed {
    .app-header {
        position: fixed;
        z-index: $zindex-sticky;
        width: 100%;
        flex-wrap: nowrap;
    }
    .app-body {
        margin-top: $navbar-height;
    }
}

//
// Sidebar
//
.sidebar-hidden {
    .sidebar {
        margin-left: - $sidebar-width;
    }
}

.sidebar-fixed {
    .sidebar {
        position: fixed;
        z-index: $zindex-sticky - 1;
        width: $sidebar-width;
        height: calc(100vh - #{$navbar-height}); 
        // margin-top: - $navbar-height;
        // .sidebar-nav {
        //   height: calc(100vh - #{$navbar-height});
        // }
    }

    .main,
    .app-footer {
        margin-left: $sidebar-width;
    }

    &.sidebar-hidden {
        .main,
        .app-footer {
            margin-left: 0;
        }
    }
}

.sidebar-off-canvas {
    .sidebar {
        position: fixed;
        z-index: $zindex-sticky - 1;
        height: calc(100vh - #{$navbar-height});
    }
}

@include media-breakpoint-up(lg) {
    .sidebar-compact {
        .sidebar {
            flex: 0 0 $sidebar-compact-width;
        }

        &.sidebar-hidden {
            .sidebar {
                margin-left: - $sidebar-compact-width;
            }
        }

        &.sidebar-fixed {
            .main,
            .app-footer {
                margin-left: $sidebar-compact-width;
            }

            .sidebar {
                width: $sidebar-compact-width;
            }

            &.sidebar-hidden {
                .main,
                .app-footer {
                    margin-left: 0;
                }
            }
        }
        .sidebar-minimizer {
            display: none;
        }
    }

    .sidebar-minimized {
        .sidebar {
            flex: 0 0 $sidebar-minimized-width;
        }

        &.sidebar-hidden {
            .sidebar {
                margin-left: - $sidebar-minimized-width;
            }
        }

        &.sidebar-fixed {
            .main,
            .app-footer {
                margin-left: $sidebar-minimized-width;
            }

            .sidebar {
                width: $sidebar-minimized-width;
            }

            &.sidebar-hidden {
                .main,
                .app-footer {
                    margin-left: 0;
                }
            }
        }
    }
}

//
// Aside Menu
//
.aside-menu-hidden {
    .aside-menu {
        margin-right: - $aside-menu-width;
    }
}

.aside-menu-fixed {
    .aside-menu {
        position: fixed;
        right: 0;
        height: 100%;

        .tab-content {
            height: calc(100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height});
        }
    }

    .main,
    .app-footer {
        margin-right: $aside-menu-width;
    }

    &.aside-menu-hidden {
        .main,
        .app-footer {
            margin-right: 0;
        }
    }
}

.aside-menu-off-canvas {
    .aside-menu {
        position: fixed;
        right: 0;
        z-index: $zindex-sticky - 1;
        height: 100%;

        .tab-content {
            height: calc(100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height});
        }
    }
}

//
// Breadcrumb
//
.breadcrumb-fixed {
    .main {
        $breadcrumb-height: 2 * $breadcrumb-padding-y + $font-size-base + 1.5 * $spacer;
        padding-top: $breadcrumb-height;
    }

    .breadcrumb {
        position: fixed;
        top: $navbar-height;
        right: 0;
        left: 0;
        z-index: $zindex-sticky - 2;
    } // if sidebar + main + aside
    .main:nth-child(2) {
        .breadcrumb {
            right: $aside-menu-width;
            left: $sidebar-width;
        }
    } // if sidebar + main
    .main:first-child {
        .breadcrumb {
            right: $aside-menu-width;
            left: 0;
        }
    } // if  main + aside
    .main:last-child {
        .breadcrumb {
            right: 0;
        }
    }

    &.sidebar-minimized {
        .main .breadcrumb {
            left: $sidebar-minimized-width;
        }
    }

    &.sidebar-hidden,
    &.sidebar-off-canvas {
        .main .breadcrumb {
            left: 0;
        }
    }

    &.aside-menu-hidden,
    &.aside-menu-off-canvas {
        .main .breadcrumb {
            right: 0;
        }
    }
}

//
// Footer
//
.footer-fixed {
    .app-footer {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $zindex-sticky;
        height: 25px;
        font-size: .75rem;
    }
}

//
// Animations
//
.app-header,
.app-footer,
.sidebar,
.main,
.aside-menu {
    transition: margin-left $layout-transition-speed, margin-right $layout-transition-speed, width $layout-transition-speed, flex $layout-transition-speed;
}

.sidebar-nav {
    transition: width $layout-transition-speed;
}

.breadcrumb {
    transition: left $layout-transition-speed, right $layout-transition-speed, width $layout-transition-speed;
}

//
// Mobile layout
//