/* Rating Star Widgets Style */
.rating-stars ul {
  list-style-type: none;
  padding: 0;

  -moz-user-select: none;
  -webkit-user-select: none;
}

.rating-stars ul>li.star {
  display: inline-block;

}

.rating-stars ul>li.star>i.fa {
  font-size: 2rem;
  color: #ccc;
}

.rating-stars ul>li.star.hover>i.fa {
  color: #FFCC36;
}

.rating-stars ul>li.star.selected>i.fa-star-o:before {
  color: #FF912C;
  content: "\f005";
}