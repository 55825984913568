.form-group {
    margin-bottom: 0;

    &+&,
    &+.row,
    .row+& {
        margin-top: 10px;
    }
}

form {
    .row+.row {
        margin-top: 10px;
    }
}

.col-form-label {
    padding-top: .3rem;
    padding-bottom: .3rem;
    font-size: .75rem;
    line-height: 1.2;
}

.input-group {
    .btn {
        border-radius: 0;
        border: 1px solid $border-color;
    }
}

.input-group-text {
    padding: .2rem .5rem;
}

label {
    font-size: .75rem;
    margin-bottom: .25rem;
}

.custom-control-label::before {
    background-color: $white;
    border: 1px solid $border-color;
    top: 2px;
}

.custom-control-label::after {
    top: 2px;
}

input[type="file"] {
    font-size: .75rem;
    padding: 0;
    line-height: 1.5;
}

.file-input-wrapper {
    color: #666;
    background: #FFF;
    border: 1px solid #999;
    padding: .25rem .5rem;
    font-size: .76563rem;
    line-height: 1.5;
}

.multiselect-native-select {

    .btn-group {
        width: 100%;
    }

    .multiselect.dropdown-toggle.btn.btn-default {
        width: 100%;
        display: block;
        text-align: left;
        background: #FFF;
        color: #3e515b;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #c2cfd6;
        border-radius: 0;
        padding: .25rem .5rem;
        font-size: .76563rem;
        line-height: 1.5;
    }

    .dropdown-toggle::after {
        border-top: .4em solid;
        border-right: .4em solid transparent;
        border-bottom: 0;
        border-left: 0.4em solid transparent;
        position: absolute;
        right: 11px;
        top: 11px;
        color: #536c79;
    }

    .multiselect-container>li>a>label {
        margin: 0;
        height: 100%;
        cursor: pointer;
        font-weight: 400;
        padding: 5px 20px 5px 10px;
        color: #3e515b;
    }
}

.form-control-readonly:disabled,
.form-control[readonly] {
    background-color: #f0f3f5;
}

select.form-control-sm {
    &:not([size]):not([multiple]) {
        height: 24.38px;
    }
}

select.form-control {
    &:not([size]):not([multiple]) {
        height: 24.38px;
    }
}

label.required::after {
    content: " *";
    color: red;
}


select.form-control:not([size]):not([multiple]) {
    height: calc(1.625rem + 2px);
}

.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
    padding: .3rem .5rem;
}

.select--btn {
    border: none;
    &:hover {
        cursor: pointer;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 4px 16px;
    }
}