.nav-tabs {

	.nav-link {
		padding: .1rem 1rem .3rem 1rem;
		border-top: 3px solid transparent;
		font-size: .75rem;

		&.active {
			border-top: 3px solid $blue;
			color: $blue;

			&:focus {
				border-top: 3px solid $blue;
			}
		}

		&:hover:not(.active) {
			border: 1px solid transparent;
			border-top: 3px solid transparent;
		}
	}
}

.tab-content {
	border-radius: 0 0 $card-radius $card-radius;
}

.tabs-vertical {
	&__wrap {

		height: calc(100vh - 213px);
		overflow-y: auto;

		&--noHeader {
			height: calc(100vh - 148px);
		}
	}

	&__nav {
		.nav-link {
			width: 100%;

			&.active {
				font-weight: bold;
				background: #edeeef;
			}
		}
	}

	&__item {
		border-bottom: 1px solid #d9dfe1;
	}

	&__content {
		margin-top: 0;
		margin-left: -2px;
		border: 0;
		border-left: 1px solid #edeeef;
		background: #edeeef;
		height: calc(100vh - 114px);
		overflow-y: auto;
		border-radius: 0;
		
		&--auto {
			height: auto;
		}
	}

	&__check {
		height: 34px;
		width: 34px;

		.custom-control-label::before,
		.custom-control-label::after {
			top: 4px;
			left: 7px;
		}
	}

	&__link {
		color: #333;
	}

	&--invert {
		.tabs-vertical {

			&__nav {
				position: absolute;
				width: 100%;
				right: 1px;
				z-index: 9;
	
				.nav-link.active, .navbar .active.dropdown-toggle, .active.dropdown-toggle {
					background: #FFF;
				}
			}  
	
			&__link {
				background: #edeeef;
			}
	
			&__content {
				border: 0;
				border: 1px solid #d9dfe1;
				background: #ffffff;
			}
		
			&__item {
				border: 1px solid #d9dfe1;
				border-right: none;
				margin-bottom: 4px;
			}

			&__nav {
				position: absolute;
				width: 100%;
				right: 1px;
				z-index: 9;
	
	
				.nav-link {
					border: 1px solid #d9dfe1;
					border-right: 1px solid #edeeef;
					margin-bottom: 4px;
	
					&.active {
						background: #FFF;
						border-left: 3px solid $main;
						border-right-color: $white;
					}
				}
	
	
			}  
	
			&__link {
				background: #edeeef;
			}
	
			&__content {
				border: 0;
				border: 1px solid #d9dfe1;
				background: #ffffff;
				border-radius: 0 4px 4px 4px;
			}
		
			&__item {
				border: none;
				margin-bottom: 4px;
			}
		}
	}
}

.scrtabs-tab-container {
	margin-right: -1.25rem;
	margin-left: -1.25rem;
	margin-bottom: -12px;
	margin-top: -12px;
	border-bottom: none;
	z-index: 99;
	position: relative;
	height: 32px;

	.card-tabs__nav {
		margin: 0;

		.nav-item {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	.card-tabs__nav .nav-item:first-child .nav-link,
	.card-tabs__nav .nav-item:first-child .navbar .dropdown-toggle,
	.navbar .card-tabs__nav .nav-item:first-child .dropdown-toggle {
		border-top-left-radius: 0;
	}

	.glyphicon {
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		&-chevron-left:before {
			content: "\f053";
		}

		&-chevron-right:before {
			content: "\f054";
		}
	}
}


.scrtabs-tabs-fixed-container {
	height: 32px;
}

.scrtabs-tab-scroll-arrow {
  border: 1px solid #c2cfd6;
  border-top: none;
  color: $main;
  display: none;
  float: left;
  font-size: 12px;
  height: 32px;
  margin-bottom: 0px;
  padding-left: 0px;
  padding-top: 6px;
	width: 26px;
	cursor: pointer;
}