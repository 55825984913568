.card {
  margin-bottom: 1.5 * $spacer;

  // Cards with color accent
  @each $color, $value in $theme-colors {
    &.bg-#{$color} {
      border-color: darken($value, 12.5%);
      .card-header {
        background-color: darken($value, 3%);
        border-color: darken($value, 12.5%);
      }

      &.card-sm .card-header .card-actions__link {
        color: #FFF;
      }
    }
  }



}

.text-white .text-muted {
  color: rgba(255,255,255,.6) !important;
}

.card {
  margin-bottom: 1.5 * $spacer;
  border-radius: $card-radius;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.09), 0 4px 4px rgba(0, 0, 0, 0.06);


  &-sm {
    .card-header {
      padding: 0.25rem 1rem;
      position: relative;

      .card-actions__link {
        width: auto;
        padding: .45rem .7rem;
        font-size: .75rem;
        line-height: 1.3;
        color: #454546;

        i {
          font-size: .9rem;
          position: relative;
        }

        &--text {
          padding: .45rem .7rem;

          i {
            top: -.1rem;
            font-size: .9rem;
            margin-right: .3rem;
          }
        }
      }
    }
    .card-body, .card-block {
      padding: .75rem;
    }
    .card-footer {
      padding: .25rem 1rem;
      font-size: .875rem;
    }
  }
}

.text-white .text-muted {
  color: rgba(255,255,255,.6) !important;
}

.card-header {
  border-radius: $card-radius $card-radius 0 0;
  font-size: 14px;
  
  .icon-bg {
    display: inline-body;
    padding: $card-spacer-y $card-spacer-x !important;
    margin-top: -$card-spacer-y;
    margin-right: $card-spacer-x;
    margin-bottom: -$card-spacer-y;
    margin-left: -$card-spacer-x;
    line-height: inherit;
    color: $card-icon-color;
    vertical-align: bottom;
    background: $card-icon-bg;
    border-right: $card-border-width solid $card-border-color;
  }

  &.card-header-inverse {
    color: #fff;
  }

  .btn {
    margin-top: - $input-btn-padding-y;
  }
  .btn-sm {
    margin-top: - $input-btn-padding-y-sm;
  }
  .btn-lg {
    margin-top: - $input-btn-padding-y-lg;
  }
}
//
.card-footer {
  @include clearfix;
  border-radius: 0 0 $card-radius $card-radius;
  ul {
    display: table;
    width: 100%;
    padding: 0;
    margin: 0;
    table-layout: fixed;

    li {
      display: table-cell;
      padding: 0 $card-spacer-x;
      text-align: center;
    }
  }

  .card-actions {
    float: right;
  }
}

[class*="card-outline-"] {
  .card-body {
    background: #fff !important;
  }

  &.card-outline-top {
    border-top-width: 2px;
    border-right-color: $border-color;
    border-bottom-color: $border-color;
    border-left-color: $border-color;
  }
}

// Card Actions
.card-header {
  > i {
    margin-right: $spacer / 2;
  }
  .card-actions {
    position: absolute;
    top: 0;
    right: 0;

    &__link {
      display: block;
      float: left;
      width: 50px;
      padding: $card-spacer-y 0;
      margin: 0 !important;
      color: $body-color;
      text-align: center;
      background: transparent;
      border: 0;
      border-left: 1px solid $border-color;
      box-shadow: none;

      &:hover {
        text-decoration: none;
      }

      [class^="icon-"], [class*=" icon-"] {
        display: inline-block;
        vertical-align: middle;
      }

      i {
        display: inline-block;
        transition: .4s;
      }

      .r180 {
        transform: rotate(180deg);
      }
    }

    .input-group {
      width: 230px;
      margin: 6px;

      .input-group-addon {
        background: #fff;
      }

      input {
        border-left: 0;
      }
    }
  }
}

.card-full {
  margin-top: - $spacer;
  margin-right: - $grid-gutter-width / 2;
  margin-left: - $grid-gutter-width / 2;
  border: 0;
  border-bottom: $card-border-width solid $border-color;
}

@include media-breakpoint-up(sm) {
  .card-columns {

    &.cols-2 {
      column-count: 2;
    }
  }
}

.card {
  &.drag, .drag {
    cursor: move;
  }
}

.card-placeholder {
  background: rgba(0,0,0,.025);
  border: 1px dashed $gray-300;
}
