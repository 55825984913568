.overlay {
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.8);

  .sk-circle .sk-child:before,
  .sk-fading-circle .sk-circle:before {
    background-color: #FFF;
  }
}