body {
  background: #EEE;
  color: #333;
  font-size: 12px;
}

dd {
  margin-bottom: .4rem;
}

.container-fluid {
  width: 100%;
  min-width: 982px;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

a {
  &:hover {
    text-decoration: none;
  }
}

h5 {
  font-size: 14px;
}

dt {
  color: #474747;
}

.block__title {
  display: inline-block;
  border-bottom: 1px solid #7aabfb;
  padding-bottom: 1px;
  color: #474747;
}

.block__sep {
  border-color: #eaeaea !important;
}

.no-rounded {
  border-radius: 0;
}
.fs-35 {
  font-size: 35px;
}

@media (max-width: 991.98px) {
  .app-header.navbar {
    position: fixed !important;
    z-index: 1020;
    width: 100%;
    text-align: center;
  }
  .app-header.navbar .navbar-toggler {
    color: #536c79;
  }
  .app-body {
    margin-top: 55px;
  }
  .sidebar {
    position: fixed;
    z-index: 1019;
    width: 200px;
    height: calc(100vh - 55px);
    margin-left: -200px;
  }
  .sidebar .sidebar-nav,
  .sidebar .nav {
    width: 200px;
    min-height: calc(100vh - 55px);
  }
  .sidebar .sidebar-minimizer {
    display: none;
  }
  .main,
  .app-footer {
    margin-left: 0 !important;
  }
  .sidebar-hidden .sidebar {
    margin-left: -200px;
  }
  .sidebar-mobile-show .sidebar {
    width: 200px;
    margin-left: 0;
  }
  .sidebar-mobile-show .main {
    margin-right: -200px !important;
    margin-left: 200px !important;
  }
  .aside-menu-fixed.aside-menu-hidden .main {
    overflow: auto;
    margin-right: 0 !important;
    
    &::-webkit-scrollbar {
      width: 10px;
      margin-right: -10px;
      -webkit-appearance: none;

      &:horizontal {
        height: 10px;
      }
    } 
    // &::-webkit-scrollbar-button {    }
    &::-webkit-scrollbar-track {
      background-color: lighten($aside-menu-bg, 5%);
      border-right: 1px solid darken($aside-menu-bg, 20%);
      border-left: 1px solid darken($aside-menu-bg, 20%);

      &:horizontal {
        border-top: 1px solid darken($aside-menu-bg, 20%);
        border-bottom: 1px solid darken($aside-menu-bg, 20%);      
        border-right:0;
        border-left:0;
      }
    } 
    // &::-webkit-scrollbar-track-piece {    }
    &::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: #d5d5d5;
      background-clip: content-box;
      border-color: transparent;
      border-style: solid;
      border-width: 1px 2px;  
      &:horizontal {
        border-width: 2px 1px;  
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.collapse__content {
  display: none;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}

.semi-bold {
  font-weight: 500;
}

:focus {
  outline: none;
}

.bg-full {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: scroll;
}

svg {
  &.icon {
    width: 80px;
  }
}

.flex-none {
  flex: none;
}