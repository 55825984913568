.file-drop-zone {
  border: 1px dashed #c3c3c3;
  margin: 8px;

  &-title {
    font-size: 1rem;
    padding: 20px 10px;
  }
}

.file-preview {
  border-radius: 0;

  .fileinput-remove {
    top: 3px;
    right: 3px;
  }
}
