.price {
    &__item {
        color: $white;
        border: none;

        &.bg-color-one {
            background: $color-one;
        }

        &.bg-color-two {
            background: $color-two;
        }

        &.bg-color-third {
            background: $color-third;
        }

        &.bg-color-four {
            background: $color-four;
        }

        &.bg-color-five {
            background: $color-five;
        }

        &.bg-color-six {
            background: $color-six;
        }

        &.disabled {
            pointer-events: none;
            cursor: not-allowed;
            background: lighten($grey, 40%);
        }

        &.active {
            use {
                fill: $white;
            }
        }
    }

    &__icon {
        width: 30px;
        height: 30px;

        use {
            fill: $grey;
        }
    }

    .server {
        &__item {
            border: none;
            border-radius: 5px 0 0 5px;
            background: $white;

            &.bg-color-one {
                background: $color-one;
            }

            &.bg-color-two {
                background: $color-two;
            }

            &.bg-color-third {
                background: $color-third;
            }

            &.bg-color-four {
                background: $color-four;
            }

            &.bg-color-five {
                background: $color-five;
            }

            &.bg-color-six {
                background: $color-six;
            }

            &.disabled {
                pointer-events: none;
                cursor: not-allowed;
                background: lighten($grey, 40%);
            }

        }

        &__list {
            height: 100%;
        }

        &__content {
            height: 100%;
        }
    }

    &__content {
        height: 100%;
    }

    .tabs-vertical {
        &__content {
            margin-left: 0;
        }

        &__nav .nav-link.active {
            color: $white;
            background: $main;

            &:after {
                border-color: $main;
            }
        }

        &__item {
            border: none;
            margin-bottom: 4px;
            position: relative;
        }

        &__wrap {
            height: 100%;
        }
    }

    .tab-content>.active {
        background-color: $main;
    }

    .custom-control.custom-checkbox {
        position: absolute;
        z-index: 99;
        left: 20px;
    }
}

.revision {
    .tabs-vertical__wrap {
        height: auto;
    }
}