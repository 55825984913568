.card {

    &-header {

        &__title {
            font-size: .875rem;
            line-height: 1.5;
            font-weight: 600;
        }
    }

    .card {
        border-radius: 0;
        box-shadow: none;
    }

    &-table {

        .card {

            &-body,
            &-content {
                background: #FFF;
            }
        }

        thead th {
            color: #333;
            background-color: #f5f5f5;
        }
    }

    &-reset {
        border-radius: 0;
        box-shadow: none;
        margin-bottom: 0;
    }

    &-xs {
        .card-header {
            padding: 0.15rem .35rem;
            position: relative;
        }

        .card-body,
        .card-block {
            padding: .35rem;
        }

        .card-footer {
            padding: .25rem 1rem;
            font-size: .875rem;
        }
    }

    &__icon {
        font-size: 2.5rem;
    }
}