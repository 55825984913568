.btn-check {
  background: $white;

  &.btn>input[type=radio],
  &.btn>input[type=checkbox] {
    display: none;
  }

  &.active {
    background: $main;
    color: $white;

    &:before {
      content: "\f00c";
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-right: 5px;
    }
  }

  &--green {
    &.active {
      @extend .btn-success;
    }
  }
}
