.app-footer {

    &--pre {
      background-color: $warning;
    }  
  
    &--des {
      background-color: #f6c377;
    }  
  
    &--test {
      background-color: #67c2ef;
    }  
  
    &--demo {
      background-color: #cbe9a8;
    }
  
      &__item {
      height: 25px;
      &:last-child,
      &:first-child {
        width: 100px;
        background: #f0f3f5;
      }
  
      &:nth-child(2),
          &:nth-child(4) {
        min-width: 280px;
        background: #f0f3f5;
      }
      }
  
    .badge-sm {
      padding: .25rem .2rem;
    }
}
