.import-bdx {
  &__main {
    max-height: 500px;
    overflow-y: auto;
    table {
      width: 100%;
      
      @media (max-width: 991.98px) {
        max-width: 600px;
      }
    }
  }
}