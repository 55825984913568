.page-header {
	&__title {
	  margin: 0.65rem 0.8rem;
	  font-size: 1.1rem;
	  line-height: 1.4;
	}

	&__badge {
		padding: .5px .5rem;
		background: $blue;
		color: $white;
		font-size: .7rem;
	}

	&__flag {
		font-size: 1.1rem;
	}
}
