.price-list {
  
  &__item {
    border: 1px solid transparent;
    transition: all 0.2s;
    position: relative;

    &:hover {
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 16px;
    }

    &.active {
      border: 1px solid $success;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 16px;
      &:hover {
        margin-top: 0;
      }
    }
  }

  &__img {
    max-height:80px;
    height: 80px;
    width: 150px;
  }



  &__info {
    position: absolute;
    left: 10px;
    top: 5px;
    &:hover {
      text-decoration: underline;
    }
  }
}