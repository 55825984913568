
.nav.card-header-tabs {
  margin-right: -($card-spacer-x);
  margin-bottom: -$card-spacer-y;
  margin-left: -($card-spacer-x);
  border-bottom: 1px solid #a4b7c1 !important;

  .nav-item {
  	&:first-child {
  		.nav-link {
  			border-top-left-radius: $card-border-radius;
  		}
  	}
		
	  .nav-link {
	  	border-top: none;
  		border-right: 1px solid $border-color;
  		padding: .5rem 1rem;
  	}
  }
}

.card-tabs {

	&__title {
		font-weight: 600;
		margin-top: -7px;
		margin-bottom: -7px;
	}

	&__nav {
		margin-right: -($card-spacer-x);
		margin-left: -($card-spacer-x);
		margin-bottom: -11px;
		border-bottom: none;
		z-index: 99;
		position: relative;

		.nav-item {
			margin-top: -$card-spacer-y;

	  	&:first-child {
	  		.nav-link {
	  			border-top-left-radius: $card-border-radius;
	  		}
	  	}
			
		  .nav-link {
		  	border-top: none;
	  		border-left: none;
	  		border-right: 1px solid $border-color;
			  padding: .4rem .8rem;
			  font-size: .75rem;
			  &:hover {
			  	border-top: none;
			  	border-left: none;
			  	border-right: 1px solid $border-color;
			  }
	  		&:focus {
	  			border-top: none;		
	  		}
	  	}
	  }

	  &--right {

	  	.nav-item {
				
		  	&:first-child {
		  		.nav-link {
		  			border-top-left-radius: 0;

		  			&.active {
		  				border-left:  1px solid $border-color;
		  			}
		  		}
		  	}

		  	&:last-child {
		  		.nav-link {
		  			border-top-right-radius: $card-border-radius;

		  			&.active {
		  				border-right:  1px solid transparent;
		  			}
		  		}
		  	}
				
			  .nav-link {
		  		border-right: 1px solid $border-color;
		  		border-left: none;

		  		&:focus {
		  			border-top: none;		
		  		}
		  	}
		  }
	  }
	}

	&__content {
		border-color: $card-border-color;
		border: none;
		border-top: 1px solid $card-border-color;
		margin-top: -2px;
	}
}