$arrow: 12px;

.vertical-tab-container {
  z-index: 10;
  padding: 0 !important;
  margin-top: 20px;
}

.server__item {
  border: none;
  background: transparent;
  color: #676767;
  font-size: 13px;
  border-top: 1px solid $white;
  border-bottom: 1px solid $white;

  &.active {
    background-color: darken($white, 3%);
    color: $main;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    font-weight: 600;
  }

  &.active:after {
    content: '';
    position: absolute;
    left: calc(100% - #{$arrow});
    top: 50%;
    margin-top: -$arrow;
    border-left: 0;
    border-bottom: $arrow solid transparent;
    border-top: $arrow solid transparent;
    border-right: $arrow solid #eeeeee;
  }
}
.vertical-tab-content {
  padding-left: 20px;
  padding-top: 10px;
}

.vertical-tab .vertical-tab-content:not(.active) {
  display: none;
}

.server {
  &__status {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #ff0000;
    display: inline-block;

    &--active {
      background: #79c447;
    }
  }

  &__icon {
    font-size: 18px;
    line-height: 1.2;
  }

  &__list {
    margin-bottom: 0;
    background: #FFF;
    height: calc(100vh - 168px);
    overflow: auto;
  }

  &__content {
      z-index: 10;
      padding: 0;
      height: calc(100vh - 168px);
      overflow-x: auto;
  }
}