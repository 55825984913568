.query-builder {
  .rules-group-container {
    padding: 10px;
    padding-bottom: 6px;
    border: 1px solid #eeeeee;
    background: rgb(240, 243, 245);
    width: 100%;
    border-radius: 0;
    margin-top: 0;;
  }

  .btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn-group>.btn:last-child:not(:first-child),
  .btn-group>.dropdown-toggle:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0;
  }

  .btn-group .btn+.btn,
  .btn-group .btn+.btn-group,
  .btn-group .btn-group+.btn,
  .btn-group .btn-group+.btn-group {
    margin-left: -1px;
  }

  .btn-xs,
  .btn-group-xs>.btn {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }

  .btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
  }

  .btn-primary {
    color: #fff;
    background: #a9aaab;
    border-color: #a9aaab;
  }

  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary.focus,
  .btn-primary:active,
  .btn-primary.active,
  .open>.dropdown-toggle.btn-primary {
    color: #fff;
    background: #81b6e4;
    border-color: #81b6e4;
  }

  select.form-control:not([size]):not([multiple]) {
    height: 26px;
    padding: 0.1rem .75rem;
  }

  .rule-value-container input[type=number],
  .rule-value-container input[type=text],
  .rule-value-container select {
    padding: 3px;
  }
}

