.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #eeeeee;
  left: 50%;
  margin-left: -1.5px;
}

.timeline>li {
  margin-bottom: 20px;
  position: relative;
}

.timeline>li:before,
.timeline>li:after {
  content: " ";
  display: table;
}

.timeline>li:after {
  clear: both;
}

.timeline>li:before,
.timeline>li:after {
  content: " ";
  display: table;
}

.timeline>li:after {
  clear: both;
}

.timeline>li>.timeline-panel {
  width: 46%;
  float: left;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  position: relative;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}

.timeline>li>.timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -12px;
  display: inline-block;
  border-top: 12px solid transparent;
  border-left: 12px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 12px solid transparent;
  content: " ";
}

.timeline>li>.timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -11px;
  display: inline-block;
  border-top: 11px solid transparent;
  border-left: 11px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 11px solid transparent;
  content: " ";
}

.timeline>li>.timeline-badge {
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: -20px;
  background-color: #f0ad4e;
  z-index: 100;
  border-radius: 50%;
}

.timeline>li.timeline-inverted>.timeline-panel {
  float: right;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
  border-left-width: 0;
  border-right-width: 12px;
  left: -12px;
  right: auto;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
  border-left-width: 0;
  border-right-width: 11px;
  left: -11px;
  right: auto;
}

.timeline-badge.primary {
  background-color: #2e6da4 !important;
}

.timeline-badge.success {
  background-color: #3f903f !important;
}

.timeline-badge.warning {
  background-color: #ffce3a !important;
}

.timeline-badge.danger {
  background-color: #fc67b4 !important;
}

.timeline-badge.info {
  background-color: #5bc0de !important;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body>p,
.timeline-body>ul {
  margin-bottom: 0;
}

.timeline-body>p+p {
  margin-top: 5px;
}

