.login {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    
  }

  &__wrapper {
    background-color: rgba(0, 0, 0, 0.3);
    color: #FFF;
  }

  &--blue {
    &::before {
      background-color: rgba(1, 83, 123, 0.67);

    }

    .login__wrapper {
      background-color: $white;
      color: #333;
      border-radius: 5px;
      box-shadow: 1px 1px 50px 3px #00000066;
    }
  }
}

