.wizard {
    border-bottom: 1px solid #ddd;

    &__item {
        border-radius: 0;
        box-shadow: none;
        border: none;
    }

    &__link {
        position: relative;
        display: block;
        padding: 6px 15px;
        margin: 0 6px;
        line-height: 1.42857143;
        border: 1px solid transparent;
        border-radius: 4px 4px 0 0;
        border: none;
        color: #bbb;
        text-decoration: none;
        outline-style: none;
        background: transparent;
        border: none;
        border: none;
        background: transparent;

        &:after {
            content: "";
            background: #4285f4;
            height: 2px;
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            transition: all 250ms ease 0s;
            transform: scale(0);
        }

        &--done {
            border: none;
            color: #000;
            background: transparent;
            .wizard__number {
                border: 2px solid #5cb85c;
                color: #5cb85c;
            }
            &:after {
                background: #5cb85c;
                transform: scale(1);
            }
        }

        &--active {
            border: none;
            color: #4285f4;
            background: transparent;
            .wizard__title {
                font-weight: bold;
            }

            &:after {
                transform: scale(1);
            }
            .wizard__number {
                border: 2px solid #4285f4;
                color: #4285f4;
            }
        }
    }

    &__title {
        font-size: 14px;
    }


    &__number {
        font-size: 15px;
        width: 24px;
        height: 24px;
        box-sizing: initial;
        background: #fff;
        border: 2px solid #bbb;
        color: #bbb;
        text-align: center;
        border-radius: 50%;
        line-height: 22px;
        box-sizing: content-box;
        float: left;
        margin-right: 0.75rem;
        font-weight: 500;
        margin-top: 0.3125rem;
    }
}