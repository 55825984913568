
$Pccolors: (  
  text:           #666,
  gray:           $gray-100,
  white:          $white,
  red:            #a02929,
  gray-lighter:   #f0f3f5,
  gray-dark:      #d1d1d1,
  main-color:     #0175bd,
  color-one:      lighten($main, 5%),
  success:        #52a41c,
  black:         #222222,
  blue:          #0175bd,
  neutral:       #EEEEEE,
);

$success:   #52a41c;
$red:       #ff5454;
$main:      #0175bd;
$color-one:   lighten($main, 55%);
$color-two:   lighten($main, 50%);
$color-third: lighten($main, 45%);
$color-four:  lighten($main, 40%);
$color-five:  lighten($main, 35%);
$color-six:   lighten($main, 30%);
$grey:      #676767;
$neutral:   #EEEEEE;

@each $item, $color in $Pccolors {
  @each $value in $color {
    .bg-#{$item} {
      background-color: $value;
    }
  }
}

@each $item, $color in $Pccolors {
  @each $value in $color {
    .#{$item} {
      color: $value;
    }
  }
}

@each $item, $color in $Pccolors {
  @each $value in $color {
    .fill-#{$item} {
      fill: $value;
    }
  }
}

.success-gradient {
	background: -moz-linear-gradient(-45deg, #6bc132 0%, #79c447 100%);
	background: -webkit-linear-gradient(-45deg, #6bc132 0%,#79c447 100%);
	background: linear-gradient(135deg, #6bc132 0%,#79c447 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6bc132', endColorstr='#79c447',GradientType=1 );
}

.blue-gradient {
  background: -moz-linear-gradient(-45deg, $blue 0%, #0693e9 100%);
  background: -webkit-linear-gradient(-45deg, $blue 0%,#0693e9 100%);
  background: linear-gradient(135deg, $blue 0%,#0693e9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$blue', endColorstr='#0693e9',GradientType=1 );
}

.danger-gradient {
  background: -moz-linear-gradient(-45deg, #e4291c 0%, #ff5454 100%);
  background: -webkit-linear-gradient(-45deg, #e4291c 0%,#ff5454 100%);
  background: linear-gradient(135deg, #e4291c 0%,#ff5454 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e4291c', endColorstr='#ff5454',GradientType=1 );
}

.warning-gradient {
  background: -moz-linear-gradient(-45deg, #ffc107 0%, #fbc420 100%);
  background: -webkit-linear-gradient(-45deg, #ffc107 0%,#fbc420 100%);
  background: linear-gradient(135deg, #ffc107 0%,#fbc420 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc107', endColorstr='#fbc420',GradientType=1 );
}
