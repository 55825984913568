.pagination-datatables, .pagination {
  li {
    .page-link {
			padding: .25rem .5rem;
			font-size: .75rem;
    }

    span {
    	color: #7e7e7e;
      cursor: context-menu !important;

    	&:hover {
    		color: #7e7e7e;
				background: transparent;
    	}
    }
  }
}