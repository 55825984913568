$typos: (
9: 9,
10: 10,
11: 11,
12: 12,
13: 13,
14: 14,
15: 15,
16: 16,
17: 17,
18: 18,
19: 19,
20: 20,
21: 21,
22: 22,
23: 23,
24: 24,
25: 25,
26: 26,
27: 27,
28: 28,
29: 29,
40: 40,

);

@each $item,
$typo in $typos {
   @each $value in $typo {
       .fs-#{$item} {
           font-size: #{$value}px;
       }
   }
}
