.badge {
    padding: .4em .8em;
    border-radius: $badge-pill-border-radius;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.1;

    i {
        margin-right: .2rem;
    }

    &-sm {
        padding: 0.2rem 0.6rem;
        font-size: 12px;
        line-height: .9;
    }

    &-square {
        border-radius: 0;
    }

    &-success {
        color: #425f21;
        background: #cbe9a8;
    }    

    &-primary {
      color: #151b1e;
      background-color: #67c2ef;
    }

    &-danger {
      color: #5d0101;
      background-color: #ffa3a3;
    }

    &-warning {
      color: #582e00;
      background-color: #ffe596;
    }

    &-border {
        border-left: 2px solid #67c2ef;
    }
}