.survey {
    &-nav {
        &__item {
            width: 100%;
            display: inline;

            &.is-visited {
                a {
                    background-color:#e4f3da;
                    color: #3f6625;

                    &:before {
                        display: inline-block;
                        font: normal normal normal 14px/1 FontAwesome;
                        font-size: inherit;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        content: "\f00c";
                        margin-right: 10px;
                    }

                    &.active {
                        background-color: $main;
                        color: $white;
                        &:before {
                            content: "";
                        }                
                    }
                }
            }
        }
        
        &__link {

            pointer-events: none;
            cursor: default;
            &.active {
                background-color: $main;
                color: $white;
                content: "";
            }
        }
    }

    &__progress{
        height: 7px;
    }

    .tab-content {
        margin-top: -1px;
        border: none;
        background: #f0f3f5;
        border-radius: 3px;

        .tab-pane {
            padding: 1.5rem;
          }
    }

    &__icon {
        font-size: 4rem
    }

    &__answer {
        border: 1px solid transparent;

        &:hover {
            cursor: pointer;
            background-color: rgb(255, 255, 255);
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 4px 16px;
        }

        &.active {
            border: 1px solid #5ab021;
            -webkit-box-shadow: rgba(0,0,0,0.1) 0px 0px 0px 1px, rgba(0,0,0,0.3) 0px 2px 11px;
            box-shadow: rgba(0,0,0,0.1) 0px 0px 0px 1px, rgba(0,0,0,0.3) 0px 2px 11px;
            
            &:hover {
                box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 4px 16px;
            }
        }

        &--input {
            border: none;
        }

        &.form-control:not([size]):not([multiple]) {
            height: 36px;
            font-size: 14px;
        }
    }

    .pager {
        background:#f0f3f5;
        .disabled {
            &>a,
            &>a:hover,
            &>a:focus,
            &>span{
                color: #777;
                cursor: not-allowed;
                background-color: #dcdcdc;
                outline: none;
            }
        }
    }
}