.app-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  color: $footer-color;
  background: $footer-bg;
	@include borders($footer-borders);
}

