.modal {

    &-header,
    &-body,
    &-footer {
        padding: .4rem 0.8rem;
    }

    &-header,
    &-footer {
        background: $gray-100;
    }

    &-title {
        font-size: 1rem;
    }

    &-full {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;

        .modal-dialog {
            position: fixed;
            margin: 0;
            width: 100%;
            height: 100%;
            padding: 0;
            max-width: 100%;
        }

        .modal-content {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 0;
            box-shadow: none;
            border: none;
        }
        .modal-header {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 50px;
            padding: 10px;
            background: $blue;
            border: 0;
            .close {
                font-size: 1.5rem;
                line-height: .9;
                opacity: .9;
                color: #FFF;
            }
        }

        .modal-title {
            font-weight: 300;
            font-size: 1.5rem;
            color: #fff;
            line-height: 30px;
        }

        .modal-body {
            position: absolute;
            top: 50px;
            bottom: 40px;
            width: 100%;
            font-weight: 300;
            overflow: auto;
            padding: 0;
                        &::-webkit-scrollbar {
                width: 10px;
                margin-right: -10px;
                -webkit-appearance: none;
            } // &::-webkit-scrollbar-button {    }
            &::-webkit-scrollbar-track {
                background-color: lighten($aside-menu-bg, 5%);
                border-right: 1px solid darken($aside-menu-bg, 20%);
                border-left: 1px solid darken($aside-menu-bg, 20%);
            } // &::-webkit-scrollbar-track-piece {    }
            &::-webkit-scrollbar-thumb {
                height: 50px;
                background-color: #d5d5d5;
                background-clip: content-box;
                border-color: transparent;
                border-style: solid;
                border-width: 1px 2px;
            }
        }

        .modal-footer {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 40px;
            padding: 10px;
            background: #f1f3f5;
        }
        .tab-content {
            border: none;
            .tab-pane {
                padding: 1rem;
                height: calc(100vh - 90px);
            }
        }
        .nav-link,
        .navbar .dropdown-toggle {
            color: #565656;
            font-weight: 400;
        }
        .nav {
            height: calc(100vh - 90px);
            overflow: auto;
            flex-direction: column;
            flex-wrap: nowrap;

            &::-webkit-scrollbar {
                width: 10px;
                margin-left: -10px;
                -webkit-appearance: none;
            } // &::-webkit-scrollbar-button {    }
            &::-webkit-scrollbar-track {
                background-color: lighten($aside-menu-bg, 5%);
                border-right: 1px solid darken($aside-menu-bg, 5%);
                border-left: 1px solid darken($aside-menu-bg, 5%);
            } // &::-webkit-scrollbar-track-piece {    }
            &::-webkit-scrollbar-thumb {
                height: 50px;
                background-color: darken($aside-menu-bg, 10%);
                background-clip: content-box;
                border-color: transparent;
                border-style: solid;
                border-width: 1px 2px;
            }
        }
    }
}