.accordion {
    &-header {
        position: relative;
        margin-bottom: 0;
        border-bottom: $card-border-width solid $card-border-color;
        font-size: 0.875rem;

        &__arrow {
            color: #333;
            font-size: .7rem;
            border-right: 1px solid $card-border-color;
            min-height: 1.5rem;
            min-width: 1.5rem;
            padding: 0 .3rem;
            margin-right: 0.4rem;
            line-height: 1;
        }

        .ml-auto {
            position: relative;
        }
    }

    &-list {
        &__item {
            padding: 0.25rem .5rem;
            border-bottom: 1px solid $card-border-color;
            background: #f7f7f7;
        }
    }
}