.query-bilder {
  &-select {
    border: 1px solid #CCC;
    li {
      margin-right: 4px;
    }

    &__list {
      li {
        margin-bottom: 6px;
      }
    }
  }
}