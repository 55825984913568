.app-responsive {

  .body {
    background: $white;
    min-height: 100vh;
  }

  @include media-breakpoint-up(sm) {
    font-size: .85rem;

  }

  .col-form-label {
    font-size: .75rem;

    @include media-breakpoint-up(sm) {
      font-size: .85rem;

    }
  }

  .timeline>li>.timeline-panel {
    width: 80%;

    @include media-breakpoint-up(sm) {
      width: 85%;
    }
  }

  .timeline:before {
    width: 4px;
    left: 7%;
    margin-left: -1.5px;
  }

  .timeline>li>.timeline-badge {
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 1.8em;
    top: 14px;
    left: 5%;
    margin-left: -20px;
    background-color: #f0ad4e;

    @include media-breakpoint-up(sm) {
      left: 5.9%;
      margin-left: -19px;
    }


    &--done {
      background-color: #49bd49;
    }

    &--active {
      background: #3f903f;
    }

    &--disabled {
      background-color: #CCC;
    }
  }

  [data-toggle=buttons]>.btn>input[type=radio],
  [data-toggle=buttons]>.btn>input[type=checkbox] {
    display: none;
  }

  .btn-state {
    padding: 10px 30px;

    &.active {
      border-color: $red;
      background: $red;
      color: $white;

      &.btn-state--plus {
        border-color: $success;
        background: $success;
      }
    }

    &+& {
      margin-left: 1rem;
    }
  }
}