.breadcrumb {
    height: 28px;
    margin-bottom: 0;
    padding: 0 0 0 1rem;
    font-size: .75rem;
    border-top: 1px solid $border-color;

    &-menu {
        .btn {
            padding: 0.3rem .5rem;
            color: #536c79;
            vertical-align: middle;
            border: 0;
            border-left: 1px solid $border-color;
            border-radius: 0;
            height: 26px;

            &.dropdown-toggle i {
                font-size: 12px;
            }
        }

        .dropdown-menu {
            position: absolute;
            right: 0px;
            left: auto;
            top: 20px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.09), 0 4px 4px rgba(0, 0, 0, 0.06);
            border-radius: 0 0 .25rem .25rem;
        }
    }
}

.header__content .breadcrumb-menu .btn.dropdown-toggle i {
    font-size: 0.75rem;
}