.table {
    background: #FFF;
    margin-bottom: .5rem;

    th,
    td {
        padding: .14rem .35rem;
        font-size: .75rem;
        vertical-align: middle;
    }

    thead {
        th {
            border-bottom-width: 1px;
        }
    }

    th {
        background-color: rgba(0, 0, 0, 0.04);

        &.sortable {
            cursor: pointer;
            background-position: right;
            background-repeat: no-repeat;
            padding-right: 30px;

            &.both {
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC');
            }

            &.asc {
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==');
            }

            &.desc {
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII= ');
            }

            &:hover {
                background-color: #e8e8e8;
            }
        }
    }

    &.table-striped tbody tr:nth-of-type(odd) {
        background-color: #FFF;
    }

    &.table-striped tbody tr:nth-of-type(even) {
        background-color: #fafafa;
    }

    &-responsive-sm {
        display: table;
    }

    &__footer {
        background: #FFF;
        font-size: .75rem;
        color: #4e4e4e;

        select {
            width: 75px;
            display: inline-block;
        }

        label {
            margin-bottom: 0;
        }
    }

    &-fixed {
        table-layout: fixed;
    }

    .badge-sm {
        padding: 0.1em 0.6em;
    }

    &__header {
        background: #c2cfd6;
        color: #4e4e4e;
        font-weight: 600;
    }

    &-ellipsis {
        display: table;
        table-layout: fixed;
        width: 100%;
        white-space: nowrap;

        span {
            white-space: nowrap;
            display: table-cell;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &-nested {

        tr[aria-expanded="true"] {
            background-color: $main;
            color: $white;

            .fa-plus:before {
                content: "\f068";
            }

        }

        tr.show {
            background: #4e4e4e;
        }

        .table {
            background-color: #f5f5f5;
        }
    }

    &-flex {
        display: flex;
        width: auto;

        &.table-bordered {
            border: none;
        }

        tr {
            border-bottom: 1px solid;
        }

        th {
            background-color: #eaeaea;
            color: #616161;
            font-weight: 400;
            white-space: nowrap;
        }

        th,
        td {
            text-align: center;
            padding: .14rem .35rem;
            white-space: nowrap;
        }

        thead th {
            vertical-align: bottom;
            border-bottom: 1px solid #c2cfd6;
        }

        table {
            display: flex;
            table-layout: fixed;
        }

        tbody {
            display: flex;
            position: relative;
            overflow-x: auto;
            overflow-y: hidden;

            max-width: 100%;
            background:
                linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)),
                linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 0 100%,
                radial-gradient(farthest-side at 0% 50%, rgba(0, 0, 0, .4), rgba(0, 0, 0, 0)),
                radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
            background-repeat: no-repeat;
            background-color: white;
            background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
            background-position: 0 0, 100%, 0 0, 100%;
            background-attachment: local, local, scroll, scroll;
        }

        td {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 23px;
        }

        .sign:after {
            content: "[+]";
        }

        .change {
            font-weight: 500;

            .sign:after {
                content: "[-]";
            }
        }

        td.d-none {
            display: none;
        }
    }

    &-sortable {

        .ui-sortable-handle:hover {
            cursor: move;
            background: #f0f3f5 !important;

            .table-sortable__icon {
                background: darken(#f0f3f5, 10%);
            }
        }

        &__icon {
            padding: .14rem .1rem !important;
            width: 8px;
            background: #f0f3f5;
        }
    }
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before {
    bottom: 3px;
    opacity: .5;
    font-size: .85rem;
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
    opacity: 1;
}

.box-sign {
    height: 10px;
    display: block;
    border-radius: 2px;
    width: 25px;
}
