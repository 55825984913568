.splitter {
    background: #FFF;
    width: 100%;
    position: relative;
    height: calc(100vh - 138px);

    &__header {
        position: sticky;
        height: 28px;
        background: $blue;
        width: 100%;
        color: $white;
    }

    &__body {
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100% - 28px);

        &::-webkit-scrollbar {
            width: 10px;
            margin-right: -10px;
            -webkit-appearance: none;
        } // &::-webkit-scrollbar-button {    }
        &::-webkit-scrollbar-track {
            background-color: lighten($aside-menu-bg, 5%);
            border-right: 1px solid darken($aside-menu-bg, 20%);
            border-left: 1px solid darken($aside-menu-bg, 20%);
        } // &::-webkit-scrollbar-track-piece {    }
        &::-webkit-scrollbar-thumb {
            height: 50px;
            background-color: #d5d5d5;
            background-clip: content-box;
            border-color: transparent;
            border-style: solid;
            border-width: 1px 2px;
        }
    }
}