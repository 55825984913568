.sideblock {
	border-radius: 0;

	&__sep {
		 border-color: #eaeaea !important;
	}

	.table th, .table td {
	  font-size: 10px;
	}

	.alert {
	  padding: .35rem .5rem;
	}
}