.collapse {

  &__wrapper {
    background-color: $white;

    &.active {
        background: #e9e9e9;
        box-shadow: 0 0px 3px 3px rgba(0, 0, 0, 0.17);
        /* background-color: #ddd; */
        border: rgba(0, 0, 0, .4);
        box-sizing: border-box;
        outline: 1px solid transparent;
        padding: 12px;
        margin: 20px 0 !important;
    }

    .custom-control-label--check {

      &::after,
      &::before {
        top: 4px;
      }

      &.active {
        font-weight: bold;
      }
    }
  }

  &__price {
    &.active {
        font-weight: bold;
    }
  }
}